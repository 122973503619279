import React from "react";
import { Link } from "gatsby";

import {
  detailSmall,
  paragraphTextSmall,
  heading100,
  heading150,
  heading200,
  heading300,
  heading500,
  paragraphTextLarge,
  paragraphTextMedium,

} from "../../../stitches/combinedStyles";
import { styled } from "../../../stitches/stitches.config";
import Button from "../Button/Button";
import CheckMark from "../../../images/checkMark.inline.svg"
import NextGenerationMechanicalBenchtop from "./nextGenerationMechanicalBenchtop.png";
import NextGenerationMechanicalHotPip from "./nextGenerationMechanicalHotPip.png";
import NextGenerationMechanicalPortable from "./nextGenerationMechanicalPortable.png";

import NextGenerationMechanicalBenchtopMobile from "./nextGenerationMechanicalBenchtopMobile.png";
import NextGenerationMechanicalHotPipMobile from "./nextGenerationMechanicalHotPipMobile.png";
import NextGenerationMechanicalPortableMobile from "./nextGenerationMechanicalPortableMobile.png";
import NextGenerationMechanicalPlxusMobile from "./nextGenerationMechanicalPlxusMobile.png";


const title = `Next generation\nmechanical testing`;
const titleMobile = `Explore our products`

const description = `We design, develop, and manufacture technologies that increase\nthe speed, ease, and flexibility of mechanical testing.`
const descriptionMobile = `We design, develop, and manufacture\ntechnologies that increase the speed and\nflexibility of mechanical testing. Check out\nour full range of products below.`



const heroLink = { path: "/contact", text: "Talk to sales" }

const NextGenerationMechanical = () => {
  const blocks = [
    {
      image: NextGenerationMechanicalBenchtop,
      imageMobile: NextGenerationMechanicalBenchtopMobile,
      title: "PLX-Benchtop",
      titleMobile: "PLX-Benchtop",
      description: "Measure metal stress-strain curves\nfrom indentation test data",
      descriptionMobile: "Accurate stress-strain\ncurves in less than 5 minutes ",
      secondaryButton: {
        text: "Learn more",
        path: "/benchtop",
        tier: "primary",
      },
      secondaryButtonMobile: {
        text: "Learn more",
        path: "/benchtop",
        tier: "primary",
      },
      secondaryTitle: undefined,
      secondaryTitleMobile: undefined,
      points: [
        "Accurate stress-strain curves\nin less than 5 minutes",
        "Small sample sizes down\nto just 3x3x1.5 mm",
        "Fully automated\ntest workflow",
        "Brinell hardness testing",
        "PLXUS compatible"
      ],
      pointsMobile: [
        "Accurate stress-strain curves\nin less than 5 minutes",
        "Small sample sizes down\nto just 3x3x1.5 mm",
        "Fully automated\ntest workflow",
        "Brinell hardness testing",
        "PLXUS compatible"
      ],
    },

    {
      image: NextGenerationMechanicalPortable,
      imageMobile: NextGenerationMechanicalPortableMobile,
      title: "PLX-Portable",
      titleMobile: "PLX-Portable",
      description: "Scalable, accurate, and fast testing of\nhigh value metal assets in the field",
      descriptionMobile: "Scalable, accurate, and fast testing of\nhigh value metal assets in the field",
      secondaryButton: {
        text: "Learn more",
        path: "http://plastometrex-6034553.hs-sites.com/pip-testing-for-pipeline-inspection",
        tier: "primary",
      },
      secondaryButtonMobile: {
        text: "Learn more",
        path: "http://plastometrex-6034553.hs-sites.com/pip-testing-for-pipeline-inspection",
        tier: "primary",
      },
      secondaryTitle: undefined,
      secondaryTitleMobile: undefined,
      points: [
        "Rugged hardware systems\nfor in-field conditions",
        "Instant and accurate results\nat the point of test",
        "Scalable deployment across\nmultiple inspection teams",
        "Automated, intuitive, and\nsecure record keeping ",
        "PLXUS compatible",
      ],
      pointsMobile: [
        "Rugged hardware systems\nfor in-field conditions",
        "Instant and accurate results\nat the point of test",
        "Scalable deployment across\nmultiple inspection teams",
        "Automated, intuitive, and\nsecure record keeping",
        "PLXUS compatible",
      ],
    },
    {
      image: NextGenerationMechanicalHotPip,
      imageMobile: NextGenerationMechanicalHotPipMobile,
      title: "PLX-HotStage",
      titleMobile: "PLX-HotStage",
      description: "Stress-strain curves from metals\nat temperatures of up-to 800°C",
      descriptionMobile: "Stress-strain curves from metals\nat temperatures of up-to 800°C",
      secondaryButton: {
        text: "Learn more",
        path: "/hotpip",
        tier: "primary",
      },
      secondaryButtonMobile: {
        text: "Learn more",
        path: "/hotpip",
        tier: "primary",
      },
      secondaryTitle: undefined,
      secondaryTitleMobile: undefined,
      points: [
        "Superfast high temperature\nmetal testing up-to 800°C",
        "Simple integration with\nthe PLX-Benchtop system ",
        "Automated, intuitive, and\nsecure record keeping",
        "PLXUS compatible"
      ],
      pointsMobile: [
        "Superfast high temperature\nmetal testing up-to 800°C",
        "Simple integration with\nthe PLX-Benchtop system ",
        "Automated, intuitive, and\nsecure record keeping",
        "PLXUS compatible"
      ],
    },
    {
      image: NextGenerationMechanicalPlxusMobile,
      imageMobile: NextGenerationMechanicalPlxusMobile,
      title: "PLXUS",
      titleMobile: "PLXUS",
      description: undefined,
      descriptionMobile: "Cloud-based collection, storage, and\nanalysis of PIP test data",
      secondaryButton: {
        text: "Coming soon",
        path: "#",
        tier: "disable",
      },
      secondaryButtonMobile: {
        text: "Coming soon",
        path: "#",
        tier: "disable",
      },
      secondaryTitle: undefined,
      secondaryTitleMobile: undefined,
      points: [],
      pointsMobile: [
        "Web-based access",
        "Test data archive",
        "Test data insights",
        "ROI metrics",
        "Sharing and collaboration tools",
      ],
    }
  ];

  const Block = ({
    image,
    title,
    description,
    secondaryButton,
    secondaryTitle,
    points
  }: {
    image: any,
    title: any,
    description: any,
    secondaryButton: any,
    secondaryTitle: any,
    points: any
  }) => {
    let buttonLink;
    if (secondaryButton.path.includes("http")) {
      buttonLink = <a href={secondaryButton.path}>
        <Button size="medium" tier={secondaryButton.tier} arrow >
          {secondaryButton.text}
        </Button>
      </a>
    } else if (secondaryButton.path === "#") {
      buttonLink = <Link to={secondaryButton.path}>
        <Button size="medium" tier={secondaryButton.tier}  >
          {secondaryButton.text}
        </Button>
      </Link>
    } else {
      buttonLink = <Link to={secondaryButton.path}>
        <Button size="medium" tier={secondaryButton.tier} arrow >
          {secondaryButton.text}
        </Button>
      </Link>
    }
    return (

      <BlockWrapper key={title}>
        <ImageWrapper><Link to={secondaryButton.path}><img src={image} /></Link></ImageWrapper>
        <BlockTitle>{title}</BlockTitle>
        <BlockDescription>{description}</BlockDescription>
        {buttonLink}
        <MobileContainer>
          {(secondaryTitle) ? <BlockSecondaryTitle style={{ color: secondaryTitle.color }}>{secondaryTitle.value}</BlockSecondaryTitle> : null}
          <IconsWrapper>
            {points.map((text: any, i: any) => (
              <Row key={i}>
                <IconWrapper >
                  <CheckMark />
                </IconWrapper>
                <IconText>{text}</IconText>
              </Row>
            ))}
          </IconsWrapper>
        </MobileContainer>
      </BlockWrapper>
    )
  };

  const BlockMobile = ({
    image,
    title,
    description,
    secondaryButton,
    secondaryTitle,
    points
  }: {
    image: any,
    title: any,
    description: any,
    secondaryButton: any,
    secondaryTitle: any,
    points: any
  }) => {

    let buttonLink;
    if (secondaryButton.path.includes("http")) {
      buttonLink = <a href={secondaryButton.path}>
        <Button size="xSmall" tier={secondaryButton.tier} arrow >
          {secondaryButton.text}
        </Button>
      </a>
    } else if (secondaryButton.path === "#") {
      buttonLink = <Link to={secondaryButton.path}>
        <Button size="xSmall" tier={secondaryButton.tier}  >
          {secondaryButton.text}
        </Button>
      </Link>
    } else {
      buttonLink = <Link to={secondaryButton.path}>
        <Button size="xSmall" tier={secondaryButton.tier} arrow >
          {secondaryButton.text}
        </Button>
      </Link>
    }

    return (

      <BlockWrapper key={title}>
        <WhiteBox>
          <BlockTitle>{title}</BlockTitle>
          <BlockDescription>{description}</BlockDescription>
          {buttonLink}
          <ImageWrapper><Link to={secondaryButton.path}><img src={image} /></Link></ImageWrapper>
        </WhiteBox>
        {(secondaryTitle) ? <BlockSecondaryTitle style={{ color: secondaryTitle.color }}>{secondaryTitle.value}</BlockSecondaryTitle> : null}
        <IconsWrapper>
          {points.map((text: any, i: any) => (
            <Row key={i}>
              <IconWrapper >
                <CheckMark />
              </IconWrapper>
              <IconText>{text}</IconText>
            </Row>
          ))}
        </IconsWrapper>

      </BlockWrapper>
    )
  };



  const PointsBlock = ({
    secondaryTitle,
    points,
  }: {
    secondaryTitle: any,
    points: any,
  }) => (
    <BlockWrapper>
      {(secondaryTitle) ? <BlockSecondaryTitle style={{ color: secondaryTitle.color }}>{secondaryTitle.value}</BlockSecondaryTitle> : null}
      <IconsWrapper>
        {points.map((text: any, i: any) => (
          <Row key={i}>
            <IconWrapper >
              <CheckMark />
            </IconWrapper>
            <IconText>{text}</IconText>
          </Row>
        ))}
      </IconsWrapper>
    </BlockWrapper>
  );

  return (
    <Container>
      <ContentContainer>
        <DesktopContainer>
          <TextContent>
            <Title>{title}</Title>
            <Description>{description}</Description>
            <WrapperLink><Link to={heroLink.path}>
              <Button size="medium" arrow >
                {heroLink.text}
              </Button>
            </Link>
            </WrapperLink>
          </TextContent>
        </DesktopContainer>
        <MobileContainer>
          <TextContent>
            <Title>{titleMobile}</Title>
            <Description>{descriptionMobile}</Description>
          </TextContent>
        </MobileContainer>

        <DesktopContainer>
          <BlockContent>
            {blocks.filter(block => block.title !== 'PLXUS').map((block, i) => (
              <Block
                image={block.image}
                title={block.title}
                description={block.description}
                secondaryButton={block.secondaryButton}
                secondaryTitle={block.secondaryTitle}
                points={block.points}
                key={i}
              />
            ))}
          </BlockContent>
          <HorizontalLine />
          <BlockContent>
            {blocks.filter(block => block.title !== 'PLXUS').map((block, i) => (
              <PointsBlock
                secondaryTitle={block.secondaryTitle}
                points={block.points}
                key={i}
              />
            ))}
          </BlockContent>
        </DesktopContainer>

        <MobileContainer>
          <BlockContent>
            {blocks.map((block, i) => (
              <BlockMobile
                image={block.imageMobile}
                title={block.titleMobile}
                description={block.descriptionMobile}
                secondaryButton={block.secondaryButtonMobile}
                secondaryTitle={block.secondaryTitleMobile}
                points={block.pointsMobile}
                key={i}
              />
            ))}
          </BlockContent>

        </MobileContainer>
      </ContentContainer>
    </Container>
  );
};

export default NextGenerationMechanical;

const Container = styled("div", {
  borderRadius: "10px",
  width: "90%",
  maxWidth: "1200px",
  margin: "0 auto",
});

const TextContent = styled("div", {

  maxWidth: "300px",
  margin: "0 auto",
  "@md": {
    maxWidth: "100%",
  },
  "@lg": {
    flexBasis: "30%",
    maxWidth: "initial",
  },
});

const Title = styled("h1", {
  ...heading150,
  marginBottom: 16,
  marginTop: 32,
  whiteSpace: "pre-line",
  fontWeight: 700,
  letterSpacing: "-0.4px",
  textAlign: "left",
  "@md": {
    ...heading500,
    marginTop: 32,
    marginBottom: 16,
    fontWeight: 700,
    letterSpacing: "-1px",
    textAlign: "center",
  },
});
const Description = styled("div", {
  ...detailSmall,
  whiteSpace: "pre-line",
  textAlign: "left",
  "@md": {
    textAlign: "center",
    fontSize: 20,
  },
});
const WrapperLink = styled("div", {
  display: "flex",
  justifyContent: "center",
  margin: "22px 0px 32px 0px",
});

const ContentContainer = styled("div", {
  textAlign: "center",
  marginBottom: "64px",
});

const BlockContent = styled("div", {
  display: "flex",
  flexDirection: "column",
  padding: "0px 0",
  "@md": {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "10px 0",
  },
})

const BlockWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  //maxWidth: 600,
  //marginLeft: "auto",
  //marginRight: "auto",
  background: "linear-gradient(to bottom, #ffffff 0%, #ffffff 189px, #F9F9F9 190px, #F9F9F9 100%)",
  "@md": {
    flexBasis: "30%",
    backgroundColor: "$white",
    background: "none",
    textAlign: "left",
    alignItems: "flex-start",
  },
});

const ImageWrapper = styled("div", {
  img: {
    maxWidth: "98%", marginTop: "0px",
    "@md": {
      maxWidth: "100%"
    }
  },
});

const BlockTitle = styled("h6", {
  ...heading200,
  margin: "26px 0 12px 0",
  textAlign: "center",
  fontWeight: 600,
  fontSize: 20,
  lineHeight: "26px",
  "@md": {
    textAlign: "left",
    width: "100%",
    fontWeight: 600,
    fontSize: 32,
    lineHeight: "38px",
  },
});

const BlockDescription = styled("p", {
  ...paragraphTextSmall,
  margin: 0,
  marginBottom: 26,
  whiteSpace: "pre-line",
  fontSize: 12,
  lineHeight: "16px",
  "@md": {
    textAlign: "left",
    width: "100%",
    fontSize: 18,
    lineHeight: "25px",
  },
});

const HorizontalLine = styled("div", {
  lineHeight: 1,
  height: 1,
  width: "100%",
  backgroundColor: "#D9D9D9",
  margin: "30px 0"
})

const BlockSecondaryTitle = styled("h6", {
  ...heading150,
  margin: "26px 0 0px 0",
  textAlign: "center",
  fontWeight: 600,
  fontSize: 14,
  lineHeight: "26px",
  "@md": {
    margin: "26px 0 12px 0",
    textAlign: "left",
    width: "100%",
    fontWeight: 600,
    fontSize: 24,
    lineHeight: "34px",
  },
});

const IconsWrapper = styled("div", {
  display: "flex",
  marginTop: 27,
  flexDirection: "column",
  maxWidth: 237,
  svg: {
    width: "20",
    marginTop: "-3px",
  },
  "@md": {

  }
});

const IconWrapper = styled("div", {
  display: "flex",
  alignItems: "flex-start",
  marginBottom: 20,
  paddingTop: 7
});

const Row = styled("div", { display: "flex", marginBottom: 26 })

const IconText = styled("div", {
  ...detailSmall,
  marginLeft: 6,
  color: "$black100",
  textAlign: "left",
  "@md": {
    ...paragraphTextSmall,
    color: "#5A7184",
    marginLeft: 12,
    fontWeight: 400,
    lineHeight: "26px",
    letterSpacing: -0.36,
    textAlign: "left",
    fontSize: 16,
    whiteSpace: 'break-spaces'
  },
});

const DesktopContainer = styled("div", {
  display: "none",
  "@md": {
    display: "block",
  },
})

const MobileContainer = styled("div", {
  display: "block",
  "@md": {
    display: "none",
  },
})

const WhiteBox = styled("div", {
  backgroundColor: "white",
  border: "1px solid rgba(26, 26, 26, 0.1)",
  borderRadius: 4,
  marginTop: 50,
  marginRight: 25,
  marginLeft: 25,
  paddingTop: 10,
  button: {
    margin: "0 auto",
    fontSize: 10,
    lineHeight: "26px",
    padding: "2px 16px",
    svg: {
      width: 10,
      display: "inline-block",
      marginBottom: "-3px"
    }
  }

}

)



