import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/Layout";
import { styled } from "../stitches/stitches.config";
import NextStep from "../components/shared/NextSteps/NextSteps";
import Plxus from "../components/shared/Plxus/Plxus";
import Newsletter from "../components/shared/Newsletter/Newsletter";
import NextGenerationMechanical from "../components/shared/NextGenerationMechanical/NextGenerationMechanical";


const seo = {
  description:
    "Profilometry-based Indentation Plastometry (PIP) is a revolutionary new approach to the mechanical testing of metals.",
};

const Products = () => {
  return (
    <Layout seo={seo} backgroundColorUnderneath="white" backgroundColor="white">
      <Container>
        <NextGenerationMechanical />
        {/* <DesktopContainer><Plxus /></DesktopContainer> */}
        <MobileContainer><Newsletter /></MobileContainer>
        <NextStep showTitle={false} />
      </Container>
    </Layout>
  );
};

export default Products;

const Container = styled("div", {
  margin: "auto",
  maxWidth: 1400,
  "@md": {
    width: "95%",
    paddingBottom: 60,
    marginTop: 0,
  },
});

const DesktopContainer = styled("div", {
  display: "none",
  "@md": {
    display: "block",
  },
})

const MobileContainer = styled("div", {
  display: "block",
  "@md": {
    display: "none",
  },
})